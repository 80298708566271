<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What is the osmotic pressure (in
        <stemble-latex content="$\text{atm)}$" />
        of a
        <stemble-latex content="$0.154\,\text{M}$" />
        solution of
        <chemical-latex content="NaCl" />
        in water that is used for intravenous infusion at body temperature,
        <stemble-latex content="$37.0\,^\circ\text{C?}$" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\Pi:$"
        append-text="$\text{atm}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemCPP1220S4Q5',
  components: {
    ChemicalLatex,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
